import { httpBatchLink, loggerLink } from "@trpc/client"
import { createTRPCNext } from "@trpc/next"
import type { AppRouter } from "../server/trpc/router"
import superjson from "superjson"
import { QueryCache } from "@tanstack/react-query"
import mobileApp from "./mobileApp"
import { mockUser } from "./mockUser"

const getBaseUrl = () => {
  if (typeof window !== "undefined") return "" // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}` // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}` // dev SSR should use localhost
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) => process.env.NODE_ENV === "development",
          // || (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          headers() {
            const email = mockUser.getUserBeingMockedEmail()

            if (email != null && email !== "lavoignat0@gmail.com") {
              return {
                mocking_user_email: email,
              }
            }

            return {}
          },
        }),
      ],
      queryClientConfig: {
        defaultOptions: { queries: { retry: false } },
        queryCache: new QueryCache({
          onError(error: any, query) {
            if (/^\/app/.test(window.location.pathname) === false) {
              // user is not on the app, so we don't redirect him to login
              return
            }

            if (error && error.message === "UNAUTHORIZED") {
              if (mobileApp.mobileAppHelper().isMobileApp) {
                mobileApp.signOut()
              } else {
                window.location.href = "/login?message=Reinicia tu sesión"
              }
            }
          },
        }),
      },
    }
  },
  ssr: false,
})
